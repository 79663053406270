import styles from "@buddieshr/styles/components/textUnderlined.module.scss";

const TextUnderlined = ({ children, app }) => (
  <span
    className={styles.text}
    style={
      app ? { background: `no-repeat 100% 1em ${app.textColorBackground}` } : {}
    }
  >
    {children}
  </span>
);

export default TextUnderlined;
